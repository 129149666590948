$elementalNavy: #001E62;
$labBlue: #0032a1;
$azure: #3366CC;
$lightBlue: #EAF0FB;
$midBlue: #316FD3;
$livermoriumIce: #eaf0fb;
$carbonGray: #a9aabc;
$quantumSlate: #63666a;
$innovationYellow: #FCC631;
$softwareBlue: #008dcb;
$softwareGreen: #00a69c;

$LLNLColors: (
    "elemental-navy": $elementalNavy,
    "lab-blue": $labBlue,
    "azure": $azure,
    "light-blue": $lightBlue,
    "mid-blue": $midBlue,
    "livermorium-ice": $livermoriumIce,
    "carbon-gray": $carbonGray,
    "quantum-slate": $quantumSlate,
    "innovation-yellow": $innovationYellow
);
$themeColors: (
    'dark-grey': #2d2d2d,
    'software-blue': $softwareBlue,
    'software-green': $softwareGreen,
    'gradient-software-blue-green': linear-gradient(to right, $softwareBlue, $softwareGreen),
    'gradient-software-blue-green-v': linear-gradient(to bottom, $softwareBlue, $softwareGreen),
    'gradient-software-green-black': linear-gradient(to right, $softwareBlue, black)
);
$otherColors: (
    'white': #FFF,
    'black': #000
);

$mainColors: map-merge(map-merge($LLNLColors, $themeColors), $otherColors);

:root {
    @each $name, $color in $mainColors {
        --#{$name}: #{$color};
    }
}

@each $name, $color in $mainColors {

    .text-#{$name},
    .text-#{$name}-hover:hover {
        color: $color !important;
    }

    .bg-#{$name},
    .bg-#{$name}-hover:hover {
        background: $color !important;
    }

    .border-right-#{$name} {
        --bs-border-color: #{$color};
    }

    .border-bottom-#{$name} {
        --bs-border-color: #{$color};
    }

    .border-left-#{$name} {
        --bs-border-color: #{$color};
    }
    
    .border-top-#{$name} {
        --bs-border-color: #{$color};
    }
}

.border-10 {
    --bs-border-width: 10px;
}

.box-shadow-16 {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

.box-shadow-16-inset {
    box-shadow: inset 0px 3px 6px 0px rgba(0, 0, 0, 0.2)
}

.box-shadow-20 {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

.box-shadow-20-inset {
    box-shadow: inset 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
}

$link-color: $azure;
$link-hover-color: #000;
$navbar-dark-color: white;
$navbar-dark-hover-color: $innovationYellow;
$table-striped-bg: $carbonGray;