@import 'colors';

.rounded-corners {
    border-radius: 0.4444rem;
}

.box-shadow {
    box-shadow: 0 -0.1111rem 0.8888rem 0 rgba(0,0,0,0.5);
    -webkit-box-shadow: 0 -0.1111rem 0.8888rem 0 rgba(0,0,0,0.5);
    -moz-box-shadow: 0 -0.1111rem 0.8888rem 0 rgba(0,0,0,0.5);
}

.circle {
    border-radius: 50%;
}

.circle-clip {
    clip-path: circle(50% at center);
}

.min-vh-100 {
    min-height: 100vh;
}

