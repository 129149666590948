@import '../colors';

$accordion-icon-color: block;
$accordion-icon-active-color: white;

.accordion {

    &.default {
        .accordion-button {
            background: $lightBlue !important;
            color: black !important;
            border-top: 1px solid $carbonGray;
            border-bottom: 1px solid $carbonGray;
            border-radius: 0 !important;

            &:focus {
                box-shadow: none;
            }

            &::after {
                background-image: none;
                font-family: 'Font Awesome 6 Pro';
                content: "\f107";
                font-size: 1.5em;
                line-height: 1;
                font-weight: 900;
                will-change: transform;
            }

            &[aria-expanded="true"] {
                background: black !important;
                color: white !important;
            }
        }     
    }
}