@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import 'colors';

$font-family-sans-serif: 'Open Sans' !default;
$headings-font-family: 'Open Sans' !default;
$headings-line-height: 1 !default;
$font-size-root: 16px !default;
$line-height-base: 1.75 !default;
$font-family-base: $font-family-sans-serif;
$font-family-code: 'Courier Prime';
$code-color: #c3002d;
$code-font-size: $font-size-root;
$paragraph-margin-bottom: 1.25rem;

$dropdown-font-size: 15px;
$dropdown-bg: #2d2d2d;
$dropdown-link-color: white;
$dropdown-border-color: none;
$dropdown-link-hover-bg: none;
$dropdown-link-hover-color: $softwareBlue;
$dropdown-link-active-color: $softwareBlue;
$dropdown-link-active-bg: $dropdown-bg;
$dropdown-dark-link-active-bg: $dropdown-bg;
$pagination-bg: none;
$pagination-border-radius: none;


$nav-link-font-size: 15px !default;

$nav-link-color: $quantumSlate;
$navbar-light-active-color: $labBlue;
$navbar-light-hover-color: $labBlue;
$navbar-light-icon-color: $azure;

$h2-font-size: 1.825rem !default;
$h2-font-weight: 700 !default;
$h3-font-size: 1.5rem !default;
$h3-font-weight: 600 !default;
$headings-line-height: 1 !default;

$pagination-font-size: 13px;
$pagination-color: $quantumSlate;
$pagination-hover-color: white;
$pagination-hover-bg: $softwareBlue;

$form-select-indicator-color: $softwareBlue;

$pagination-active-color: $pagination-hover-color;
$pagination-active-bg: $pagination-hover-bg;

$nav-link-font-size: 0.77777777rem !default;


.text-balance {
    text-wrap: balance;
}